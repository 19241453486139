import request from '@/utils/request'

export function getPage (page, noitems) {
  return request({
    url: '/news/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function getNews (id) {
  return request({
    url: '/news/' + id,
    method: 'get'
  })
}
export function search (data) {
  return request({
    url: '/news/search',
    method: 'post',
    data
  })
}
export function storeNews (data) {
  return request({
    url: '/news',
    method: 'post',
    data
  })
}
export function updateNews (id, data) {
  return request({
    url: '/news/' + id,
    method: 'put',
    data
  })
}
export function destroyNews (_id) {
  return request({
    url: '/news/' + _id,
    method: 'delete'
  })
}
export function exportExcel (data) {
  return request({
    url: '/news/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
